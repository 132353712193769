import DotEnv from 'dotenv';
import ReactGA from 'react-ga4';
import App from './containers/App';
import { Amplify } from 'aws-amplify';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';

import GeneralContextProvider from './context/GeneralContext';
import theme from './theme';
import './index.module.scss';
import './i18n';

DotEnv.config();

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-2',

    // OPTIONAL - Amazon Cognito User Pool ID

    userPoolId: 'us-east-2_Ji1PrZh4b',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '7htptsoeojjl045blmlhce5ho0',

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Hosted UI configuration
    /* oauth: {
        domain: 'your_cognito_domain',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'http://localhost:3000/',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    } */
  },
});

if (window?.location?.host === 'kinsu.mx') {
  ReactGA.initialize([
    {
      trackingId: 'GTM-P5F3G7X',
      gaOptions: {
        name: 'tracking1',
      },
    },
    {
      trackingId: 'G-D5LRQJVQZT',
      gaOptions: {
        name: 'tracking2',
      },
    },
    {
      trackingId: 'G-P1ME3VX4ER',
      gaOptions: {
        name: 'tracking3',
      },
    },
  ]);
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ThemeProvider theme={theme}>
    <GeneralContextProvider>
      <App />
    </GeneralContextProvider>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
